<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Фильтры
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="6"
                md="6"
                class="mb-md-0 mb-2"
            >
              <label>Тип</label>
              <v-select
                  v-model="filter.type"
                  :options="typeOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
                  @select="fetchMoviesList()"
              />
            </b-col>
            <b-col
                cols="6"
                md="6"
                class="mb-md-0 mb-2"
            >
              <label>Сортировка</label>
              <v-select
                  v-model="filter.order"
                  :options="orderOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
                  @select="fetchMoviesList()"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
                cols="3"
                md="3"
                class="mb-md-0 mb-2"
            >
              <b-form-input
                  id="filter-year-from"
                  v-model="filter.yearFrom"
                  placeholder="Годы от..."
                  @keyup.enter="fetchMoviesList()"
              />
            </b-col>
            <b-col
                cols="3"
                md="3"
                class="mb-md-0 mb-2"
            >
              <b-form-input
                  id="filter-year-to"
                  v-model="filter.yearTo"
                  placeholder="Годы до..."
                  @keyup.enter="fetchMoviesList()"
              />
            </b-col>
            <b-col
                cols="6"
                md="6"
                class="mb-md-0 mb-2"
            >
              <b-form-input
                  id="filter-keyword"
                  v-model="filter.keyword"
                  placeholder="Поисковая фраза"
                  @keyup.enter="fetchMoviesList()"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-table
          ref="refMoviesListTable"
          :items="moviesList"
          responsive
          :fields="tableColumns"
          primary-key="kinopoiskId"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
              v-if="data.item.db"
              :to="{ name: 'movie-view', params: { id: data.item.db.id }}"
              class="font-weight-bold"
          >
            #{{ data.item.kinopoiskId }}
          </b-link>
          <span
              v-else
          >
            #{{ data.item.kinopoiskId }}
          </span>
        </template>

        <!-- Column: Client -->
        <template #cell(movie)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.posterUrlPreview"
                  :text="avatarText(data.item.nameRu)"
                  variant="primary"
              />
            </template>
            <span :class="'font-weight-bold d-block text-nowrap' + ((!data.item.db) ? ' text-danger' : '')">
              {{ data.item.nameRu }} ({{ data.item.year }})
            </span>
            <small class="text-muted">{{ data.item.db ? data.item.db.title_en : data.item.nameEn }}</small>
          </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(rating)="data">
          <span class="font-weight-bold font-large-1 ml-1" v-if="data.item.db">
            <span
                :class="(data.item.db.rating_kp < 5) ? 'text-danger' : (data.item.db.rating_kp < 7) ? 'text-gray' : 'text-success'"
            >
              {{ data.item.db.rating_kp }}
            </span>
          </span>
          <span class="font-weight-bold font-large-1 ml-1" v-else>
            <span
                :class="(data.item.ratingKinopoisk < 5) ? 'text-danger' : (data.item.ratingKinopoisk < 7) ? 'text-gray' : 'text-success'"
            >
              {{ data.item.ratingKinopoisk }}
            </span>
          </span>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(votes)="data">
          <span class="text-nowrap mr-1" v-if="data.item.db">
             {{ data.item.db.votes_kp }}
          </span>
          <span class="text-nowrap mr-1" v-else>
             -
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-button
                v-if="!data.item.db"
                variant="success"
                class="btn-icon"
                size="sm"
                @click="addMovie(data.item)"
                :disabled="(disabled===data.item.kinopoiskId)"
            >
              <feather-icon icon="UserPlusIcon"/>
            </b-button>
            <b-button
                v-else
                :to="{name: 'movie-view', params: { id: data.item.db.id }}"
                variant="primary"
                class="btn-icon"
                size="sm"
            >
              <feather-icon icon="ArrowRightCircleIcon"/>
            </b-button>
            <b-button
                :href="`https://kinopoisk.ru/film/${data.item.kinopoiskId}`"
                variant="warning"
                class="btn-icon ml-1"
                size="sm"
            >
              <feather-icon icon="CastIcon"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-model="page"
              per-page="20"
              align="center"
              :total-rows="total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton,
} from 'bootstrap-vue'
import {avatarText, kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import useMoviesList from "./useMoviesList";
import movieStoreModule from "@/views/database/movieStoreModule";
import vSelect from "vue-select";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
    }
  },
  methods: {
    kFormatter,
    addMovie(movie) {
      this.disabled = movie.kinopoiskId
      this.$http.post(`/movie`, {kp_id: movie.kinopoiskId, 'title_ru': movie.nameRu}).then(res => {
        if (res.data.data) {
          this.fetchMoviesList()
        }
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const total = ref(0)
    const page = ref(1)
    const filter = ref({
      type: 'ALL',
      order: 'NUM_VOTE',
      yearFrom: 1000,
      yearTo: 3000,
      keyword: '',
      page: 1,
    })

    const typeOptions = [
      {label: 'Фильмы', value: 'FILM'},
      {label: 'ТВ-шоу', value: 'TV_SHOW'},
      {label: 'Сериалы', value: 'TV_SERIES'},
      {label: 'Мини-Сериалы', value: 'MINI_SERIES'},
      {label: 'Все', value: 'ALL'},
    ]

    const orderOptions = [
      {label: 'Рейтинг', value: 'RATING'},
      {label: 'Количество оценок', value: 'NUM_VOTE'},
      {label: 'Год', value: 'YEAR'},
    ]

    // Table Handlers
    const tableColumns = [
      {key: 'id', label: '#', sortable: false},
      {key: 'movie', sortable: false},
      {key: 'rating', sortable: false},
      {key: 'votes', sortable: false},
      {key: 'actions'},
    ]

    const moviesList = ref([])
    const fetchMovies = (...args) => store.dispatch('movies/fetchMoviesFilter', ...args)

    const fetchMoviesList = () => {
      fetchMovies({
        type: filter.value.type,
        page: page.value,
        yearFrom: filter.value.yearFrom,
        yearTo: filter.value.yearTo,
        keyword: filter.value.keyword,
        order: filter.value.order,
      })
          .then(response => {
            moviesList.value = response.data.data
            total.value = response.data.meta.total
          })
    }

    fetchMoviesList()

    watch([page], () => {
      fetchMoviesList()
    }, {
      deep: true,
    })

    const {
      currentPage,
      totalMovies,
      dataMeta,
      isSortDirDesc,
      refMovieListTable,
    } = useMoviesList()

    return {
      fetchMovies,
      tableColumns,
      currentPage,
      totalMovies,
      dataMeta,
      isSortDirDesc,
      refMovieListTable,
      moviesList,
      typeOptions,
      orderOptions,
      filter,
      page,
      total,
      avatarText,
      fetchMoviesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
